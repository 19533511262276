<template>
  <b-form >
    <b-row>
      <b-col cols="12">
        <h3>
          حسب منتج
        </h3>
        <p class="text-secondary">
          مثال: المتسوقون الذين اشتروا iPhone 12 pro
        </p>
      </b-col>
      <b-col cols="12">
        <b-form-group
          :label="$t('اختر المنتج')"
          label-for="product"
        >
          <v-select
            v-model="product"
            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
            :placeholder="$t('اختر المنتج')"
            label="name"
            :reduce="p => p.id"
            :options="products"
            @search="onProductSearch"
          />
        </b-form-group>
      </b-col>

    </b-row>
    <send-sms
      v-if="shoppersId && shoppersId.length > 0"
      :ids="shoppersId"
    />
  </b-form>
</template>

<script>
import { debounce } from 'debounce'
import SendSms from '@/components/SendSms.vue'
import { getShoppersId } from '@/services/criteria'
import useJwt from '@/auth/useJwt'

export default {
  name: 'ByProduct',
  components: { SendSms },
  props: {
    criteria: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      shoppersId: null,
      product: null,
      products: [],
    }
  },
  watch: {
    product() {
      this.getDataFromApi()
    },
  },
  mounted() {
    this.getProducts()
  },
  methods: {
    getProducts(search = null, loading = null) {
      useJwt.post('/products/search', {
        search,
      }).then(({ data }) => {
        this.products = data.data.data
      }).finally(() => {
        if (loading) {
          loading(false)
        }
      })
    },
    // eslint-disable-next-line func-names
    getDataFromApi: debounce(function () {
      getShoppersId(this.criteria, {
        product: this.product,
      }).then(({ data }) => {
        this.shoppersId = data.shoppers
      }).catch(() => {
        this.shoppersId = []
      })
    }, 300),
    onProductSearch: debounce(function (search, loading) {
      if (search.length) {
        loading(true)
        this.getProducts(search, loading)
      }
    }, 350),
  },
}
</script>

<style lang="scss">
</style>
