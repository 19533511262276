<template>
  <b-form>
    <b-row>
      <b-col cols="12">
        <h3>
          حسب الطلبات
        </h3>
        <p class="text-secondary">
          مثال: المشترون الذين لديهم 5 طلبات أو أكثر
        </p>
      </b-col>
      <b-col cols="12">
        <b-form-group
          :label="$t('عدد الطلبات')"
          label-for="orders_number"
        >
          <b-input-group>
            <b-form-input
              id="orders_number"
              v-model="count"
              placeholder="5"
            />
            <b-input-group-append is-text>
              {{ $t('طلبات') }}
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

    </b-row>
    <send-sms
      v-if="shoppersId.length > 0"
      :ids="shoppersId"
    />
  </b-form>
</template>

<script>

import { debounce } from 'debounce'
import SendSms from '@/components/SendSms.vue'
import { getShoppersId } from '@/services/criteria'

export default {
  name: 'ByOrders',
  components: { SendSms },
  props: {
    criteria: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      shoppersId: [],
      isLoading: false,
      isLoadingSave: false,
      count: null,
    }
  },
  watch: {
    count() {
      this.getDataFromApi()
    },
  },
  methods: {
    // eslint-disable-next-line func-names
    getDataFromApi: debounce(function () {
      getShoppersId(this.criteria, {
        count: this.count,
      }).then(({ data }) => {
        this.shoppersId = data.shoppers
      }).catch(() => {
        this.shoppersId = []
      })
    }, 300),
  },
}
</script>

<style lang="scss">
</style>
