<template>
  <b-form>
    <b-row>
      <b-col cols="12">
        <h3>
          حسب المدينة
        </h3>
        <p class="text-secondary">
          مثال: المتسوقون من مدينة الرياض
        </p>
      </b-col>
      <b-col cols="12">
        <b-form-group
          :label="$t('اختر المدينة')"
          label-for="city"
        >
          <v-select
            v-model="city"
            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
            :placeholder="$t('اختر المدينة')"
            label="name"
            :reduce="c => c.id"
            :options="cities"
          />
        </b-form-group>
      </b-col>

    </b-row>
    <send-sms
      v-if="shoppersId.length > 0"
      :ids="shoppersId"
    />
  </b-form>
</template>

<script>
import { debounce } from 'debounce';
import SendSms from '@/components/SendSms.vue'
import useJwt from '@/auth/useJwt'
import { getShoppersId } from '@/services/criteria';

export default {
  name: 'ByCity',
  components: { SendSms },
  props: {
    criteria: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      shoppersId: [],
      city: null,
      cities: [],
    }
  },
  watch: {
    city() {
      this.getDataFromApi()
    },
  },
  mounted() {
    this.getCities()
  },
  methods: {
    getCities() {
      useJwt.get('/cities/get-all').then(({ data }) => {
        this.cities = data.data
      }).catch(() => this.city = null)
    },
    getDataFromApi: debounce(function () {
      getShoppersId(this.criteria, {
        city: this.city,
      }).then(({ data }) => {
        this.shoppersId = data.shoppers
      }).catch(err => {
        console.error(err)
      })
    }, 300),
  },
}
</script>

<style lang="scss">
</style>
