<template>
  <b-form>
    <b-row>
      <b-col cols="12">
        <h3>
          حسب طريقة الدفع
        </h3>
        <p class="text-secondary">
          مثال: المتسوقون الذين اشتروا باستخدام طريقة الدفع عند التسليم
        </p>
      </b-col>
      <b-col cols="12">
        <b-form-group
          :label="$t('اختر طريقة الدفع')"
          label-for="payment"
        >
          <v-select
            v-model="paymentMethod"
            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
            :placeholder="$t('اختر طريقة الدفع')"
            label="theName"
            :reduce="pm => pm.id"
            :options="paymentMethods"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <send-sms
      v-if="shoppersId && shoppersId.length > 0"
      :ids="shoppersId"
    />
  </b-form>
</template>

<script>
import { debounce } from 'debounce'
import { getShoppersId } from '@/services/criteria'
import SendSms from '@/components/SendSms.vue'
import useJwt from '@/auth/useJwt'

export default {
  name: 'ByPayment',
  components: { SendSms },
  props: {
    criteria: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      shoppersId: null,
      paymentMethod: null,
      paymentMethods: [],
    }
  },
  watch: {
    paymentMethod() {
      this.getDataFromApi()
    },
  },
  mounted() {
    this.getPaymentMethods()
  },
  methods: {
    getPaymentMethods() {
      useJwt.get('/payment-methods/get-all').then(({ data }) => {
        this.paymentMethods = data.data
      })
    },
    // eslint-disable-next-line func-names
    getDataFromApi: debounce(function () {
      getShoppersId(this.criteria, {
        payment_method: this.paymentMethod,
      }).then(({ data }) => {
        this.shoppersId = data.shoppers
      }).catch(() => {
        this.shoppersId = []
      })
    }, 300),
  },
}
</script>

<style lang="scss">
</style>
