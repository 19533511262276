<template>
  <b-row>

    <b-col cols="12">
      <b-form-textarea
        id="v-message"
        v-model="message"
        placeholder="اكتب الرسالة التي تريد إرسالها هنا"
        rows="4"
        class="mt-1"
        @input="countChars"
      />
    </b-col>

    <b-col
      cols="12"
      class="mt-1"
    >
      <span> عدد العملاء <b-badge variant="primary">{{ ids.length }}</b-badge> </span>
      <span> مجموع الرسائل <b-badge variant="primary">{{ ids.length }}</b-badge> </span>
    </b-col>

    <!-- submit -->
    <b-col
      class="mt-2"
      cols="12"
    >
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="button"
        variant="primary"
        class="mr-1"
        :disabled="!canSend"
        @click="send"
      >
        <template v-if="!isLoading">
          {{ $t('Send') }}
        </template>
        <template v-if="isLoading">
          {{ $t('Sending') }}...
          <b-spinner small />
        </template>
      </b-button>
    </b-col>
  </b-row>

</template>

<script>

import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/useJwt'

export default {
  name: 'SendSms',
  directives: {
    Ripple,
  },
  props: {
    ids: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      message: null,
      isLoading: false,
    }
  },
  computed: {
    canSend() {
      if (this.isLoading) {
        return false
      }

      if (this.ids.length === 0) {
        return false
      }

      return this.message
    },
  },
  methods: {
    send() {
      this.isLoading = true
      useJwt.post('/shoppers/send-sms', {
        shoppers: this.ids,
        message: this.message,
      }).then(() => {
        this.$root.showToast('success', 'sent', '')
      }).catch(err => {
        this.$root.showAlert('error', err.response.data.message)
      }).finally(() => {
        this.isLoading = false
      })
    },
    countChars() {
      console.log('')
    },
  },
}
</script>

<style scoped lang="scss">

</style>
