<template>
  <div>
    <b-overlay
      :show="isLoading"
      rounded
      opacity="0.6"
      spinner-variant="primary"
      spinner-mediam
    >
      <transition
        :name="$store.state.appConfig.layout.routerTransition"
        mode="out-in"
      >

        <b-row>
          <b-col cols="9">
            <b-card no-body>
              <b-card-body class="">

                <b-row>
                  <b-col
                    class="mb-2"
                    cols="12"
                  >
                    <h3>إشعارات التسويق</h3>
                    <div>
                      تساعدك هذه الميزة في الحصول على بعض المتسوقين وفقًا لمعايير لإرسال إشعارات إليهم عبر الرسائل القصيرة والبريد الإلكتروني
                    </div>
                  </b-col>
                  <b-col cols="6">
                    <div class="text-left">
                      {{ $t('Select marketing notification type') }}
                    </div>
                    <v-select
                      v-model="criteria"
                      :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :reduce="c => c.value"
                      :options="options"
                    >
                      <template #option="{ name, icon }">
                        <b-avatar variant="success">
                          <feather-icon
                            class="text-light"
                            :icon="icon"
                            size="18"
                          />
                        </b-avatar>
                        <span class="ml-1">{{ name }}</span>
                      </template>
                    </v-select>
                  </b-col>
                </b-row>

                <hr class="mt-2 mb-2">

                <b-card
                  class="border"
                  no-body
                >
                  <b-card-body>
                    <by-period
                      v-if="criteria === 1"
                      :criteria="criteria"
                    />
                    <by-order
                      v-if="criteria === 2"
                      :criteria="criteria"
                    />
                    <by-price
                      v-if="criteria === 3"
                      :criteria="criteria"
                    />
                    <by-product
                      v-if="criteria === 4"
                      :criteria="criteria"
                    />
                    <by-payment
                      v-if="criteria === 5"
                      :criteria="criteria"
                    />
                    <by-city
                      v-if="criteria === 6"
                      :criteria="criteria"
                    />
                    <div v-if="!criteria">
                      الرجاء اختيار نوع إشعار التسويق
                    </div>
                  </b-card-body>
                </b-card>

              </b-card-body>
            </b-card>
          </b-col>
        </b-row>

      </transition>
    </b-overlay>
  </div>
</template>

<script>
import ByPeriod from '@/views/marketing-offers/marketing-notifications/ByPeriod.vue'
import ByOrder from '@/views/marketing-offers/marketing-notifications/ByOrder.vue'
import ByPrice from '@/views/marketing-offers/marketing-notifications/ByPrice.vue'
import ByProduct from '@/views/marketing-offers/marketing-notifications/ByProduct.vue'
import ByCity from '@/views/marketing-offers/marketing-notifications/ByCity.vue'
import ByPayment from '@/views/marketing-offers/marketing-notifications/ByPayment.vue'

export default {
  components: {
    ByPeriod, ByOrder, ByPrice, ByProduct, ByCity, ByPayment,
  },
  data() {
    return {
      criteria: null,
      isLoading: false,
      options: [
        { name: this.$t('By period'), icon: 'CalendarIcon', value: 1 },
        { name: this.$t('By number of orders'), icon: 'LayersIcon', value: 2 },
        { name: this.$t('By total price'), icon: 'DollarSignIcon', value: 3 },
        { name: this.$t('By specific product'), icon: 'CodesandboxIcon', value: 4 },
        { name: this.$t('By payment method'), icon: 'CreditCardIcon', value: 5 },
        { name: this.$t('By city'), icon: 'MapPinIcon', value: 6 },
      ],
    }
  },
  watch: {},
  mounted() {},
}
</script>

<style lang="scss">

</style>
