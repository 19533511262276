<template>
  <b-form>
    <b-row>
      <b-col cols="12">
        <h3>
          حسب الفترة
        </h3>
        <p class="text-secondary">
          مثال: المشترون الذين لا يشترون خلال فترة شهر واحد (حدد نطاقًا مدته شهر واحد)
        </p>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          :label="$t('Select the order period type')"
          label-for="period"
        >
          <v-select
            v-model="rangeType"
            label="name"
            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
            :reduce="c => c.value"
            :options="rangeTypes"
          >
            <template #option="{ name, icon }">
              <b-avatar variant="success">
                <feather-icon
                  class="text-light"
                  :icon="icon"
                  size="18"
                />
              </b-avatar>
              <span class="ml-1">{{ name }}</span>
            </template>
          </v-select>
        </b-form-group>
      </b-col>
      <b-col
        v-if="rangeType === 'custom'"
        cols="12"
        md="6"
      >
        <b-form-group
          :label="$t('Select the order period')"
          label-for="period"
        >
          <flat-pickr
            v-model="rangeDate"
            class="form-control"
            :config="flatPickrConfig"
            :placeholder="$t('Date range')"
            @on-change="getDataFromApi"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <send-sms
      v-if="shoppersId.length > 0"
      :ids="shoppersId"
    />
  </b-form>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Arabic } from 'flatpickr/dist/l10n/ar.js'
import { getShoppersId } from '@/services/criteria'
import SendSms from '@/components/SendSms.vue'

export default {
  name: 'ByPeriod',
  components: {
    flatPickr, SendSms,
  },
  props: {
    criteria: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      rangeType: '',
      rangeTypes: [
        { name: this.$t('Time range'), icon: 'CalendarIcon', value: 'custom' },
        { name: this.$t('More than 1 month'), icon: 'CalendarIcon', value: 1 },
        { name: this.$t('More than 2 month'), icon: 'CalendarIcon', value: 2 },
        { name: this.$t('More than 3 month'), icon: 'CalendarIcon', value: 3 },
        { name: this.$t('More than 6 month'), icon: 'CalendarIcon', value: 6 },
        { name: this.$t('More than 12 month'), icon: 'CalendarIcon', value: 12 },
      ],
      shoppersId: [],
      rangeDate: null,
      flatPickrConfig: {
        altFormat: 'j F Y',
        altInput: true,
        dateFormat: 'Y-m-d',
        locale: Arabic,
        parseDate: true,
        mode: 'range',
      },
    }
  },
  watch: {
    rangeType() {
      this.shoppersId = []
      this.rangeDate = null
      this.getDataFromApi()
    },
  },
  methods: {
    getDataFromApi(selectedDates = null) {
      if (this.rangeType !== 'custom') {
        getShoppersId(this.criteria, {
          type: this.rangeType,
        }).then(({ data }) => {
          this.shoppersId = data.shoppers
        }).catch(err => {
          console.error(err)
        })
        return
      }

      if (!selectedDates || selectedDates.length < 2) {
        return
      }

      getShoppersId(this.criteria, {
        type: this.rangeType,
        from: selectedDates[0],
        to: selectedDates[1],
      }).then(({ data }) => {
        this.shoppersId = data.shoppers
      }).catch(err => {
        console.error(err)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
