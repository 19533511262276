<template>
  <b-form>
    <b-row>
      <b-col cols="12">
        <h3>
          حسب السعر إجمالي
        </h3>
        <p class="text-secondary">
          مثال: المتسوقون الذين لديهم طلبات بسعر إجمالي 1500 ريال أو أكثر
        </p>
      </b-col>
      <b-col cols="12">
        <b-form-group
          :label="$t('السعر إجمالي')"
          label-for="price"
        >
          <b-input-group>
            <b-form-input
              id="price"
              v-model="amount"
              placeholder="1500"
            />
            <b-input-group-append is-text>
              {{ $t('SAR') }}
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <send-sms
      v-if="shoppersId.length > 0"
      :ids="shoppersId"
    />
  </b-form>
</template>

<script>
import { debounce } from 'debounce'
import SendSms from '@/components/SendSms.vue'
import { getShoppersId } from '@/services/criteria'

export default {
  name: 'ByPrice',
  components: { SendSms },
  props: {
    criteria: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      shoppersId: [],
      amount: null,
    }
  },
  watch: {
    amount() {
      this.getDataFromApi()
    },
  },
  methods: {
    // eslint-disable-next-line func-names
    getDataFromApi: debounce(function () {
      getShoppersId(this.criteria, {
        amount: this.amount,
      }).then(({ data }) => {
        this.shoppersId = data.shoppers
      }).catch(err => {
        console.error(err)
      })
    }, 300),
  },
}
</script>

<style lang="scss">
</style>
